<template>
	<Multiselect
		v-bind="$attrs"
		v-on="$listeners"
		:openDirection="openDirection"
		ref="select"
		@open="onOpen"
	>
		<template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
			<slot :name="slot" v-bind="scope"/>
		</template>
	</Multiselect>
</template>

<script>
import Multiselect from '@vueform/multiselect/dist/multiselect.vue2'

export default {
	components: {
		Multiselect,
	},
	data() {
		return {
			openDirection: this.$attrs?.openDirection || 'bottom',
			autoDirection: !this.$attrs?.openDirection,
		}
	},
	mounted() {
		this.$refs.select.$el.addEventListener('mousedown', event => {
			if (event.target.classList.contains('multiselect-clear-icon')) {
				setTimeout(() => {
					this.$refs.select.close()
				})
			}
		});
	},
	methods: {
		onOpen() {
			if (!this.autoDirection) {
				return
			}

			this.openDirection = 'bottom'

			this.$nextTick(() => {
				const dropdown = this.$refs.select.$el.querySelector('.multiselect-dropdown')
				const windowHeight = window.innerHeight
				const dropdownOffsetTop = dropdown.getBoundingClientRect().top
				const selectHalfHeight = this.$refs.select.$el.offsetHeight / 2
				const dropdownHeight = dropdown.offsetHeight
				const overflow = dropdownOffsetTop + dropdownHeight - windowHeight

				if (dropdownOffsetTop > 0 && overflow > 0 && (dropdownOffsetTop - selectHalfHeight) > windowHeight / 2) {
					this.openDirection = 'top'
				} else if (dropdownOffsetTop < 0 && (dropdownOffsetTop + selectHalfHeight + dropdownHeight) < windowHeight / 2) {
					this.openDirection = 'bottom'
				}
			})
		},
	}
}
</script>

