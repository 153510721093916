<i18n lang="json5">{
	ru: {
		easy_route: 'Вернуть простой маршрут',
		multi_route: 'Сложный маршрут',
		from_placeholder: 'Откуда',
		from_required: 'Укажите город вылета',
		where_placeholder: 'Куда',
		where_required: 'Укажите город прибытия',
		where_equal: 'Город вылета не должен совпадать с городом прилета',
		there: 'Туда',
		back: 'Обратно',
		passenger_count: '0 пассажиров | {n} пассажир | {n} пассажира | {n} пассажиров',
		date_required: 'Укажите дату',
		submit: 'Оформить полис',
		departure_date: 'Дата вылета',
		add_flight: 'Добавить перелет',
		TAS: 'Ташкент',
		SKD: 'Самарканд',
		BHK: 'Бухара',
		UGC: 'Ургенч',
		MOW: 'Москва',
		IST: 'Стамбул',
		NCU: 'Нукус',
		LED: 'С. Петербург',
		ALA: 'Алматы',
		NQZ: 'Астана',
		VNO: 'Вильнюс',
		RIX: 'Рига',
		TLL: 'Таллин',
		TBS: 'Тбилиси',
		FEG: 'Фергана',
		DXB: 'Дубай',
	},
	'uz': {
		easy_route: 'Oddiy marshrutni qaytarish',
		multi_route: 'Murakkab marshrut',
		from_placeholder: 'Qayerdan',
		from_required: 'Uchish shahrini koʼrsating',
		where_placeholder: 'Qayerga',
		where_required: 'Qoʼnish shaharni koʼrsating',
		where_equal: 'Uchish shahri qoʼnish shahri bilan bir xil boʼlmasligi kerak',
		there: 'Ketish',
		back: 'Qaytish',
		passenger_count: '0 yoʼlovchi | {n} yoʼlovchi',
		date_required: 'Sanasini koʼrsating',
		submit: 'Polisni rasmiylashtirish',
		departure_date: 'Uchib ketish sanasi',
		add_flight: 'Parvoz qoʼshish',
		TAS: 'Toshkent',
		SKD: 'Samarqand',
		BHK: 'Buxoro',
		UGC: 'Urganch',
		MOW: 'Moskva',
		IST: 'Istanbul',
		NCU: 'Nukus',
		LED: 'S. Peterburg',
		FEG: 'Fargʼona',
		DXB: 'Dubay',
	}
}</i18n>

<template>
	<div :class="{'sm:min-h-[27rem] lg:min-h-[14rem]': $route.name === 'home'}">
		<div class="hidden sm:block text-left opacity-75">
			<span
				class="inline-block w-4 h-4 border-2 border-white rounded-lg align-middle cursor-pointer"
				:class="{'bg-white': moses}"
				@click="mosesToggle"
			/>
			<span
				class="inline-block text-white ml-3 align-middle cursor-pointer"
				@click="mosesToggle"
			>
				<template v-if="moses">{{ $t('easy_route') }}</template>
				<template v-else>{{ $t('multi_route') }}</template>
			</span>
		</div>

		<!--Regular-->
		<div class="mt-6" v-if="!moses">

				<div class="xl:flex">
					<div class="lg:flex xl:flex-grow">
						<div class="lg:flex-grow lg:border-r lg:border-my-gray-400">
							<AirportHints
								v-model="form.departure[0]"
								:show-airports-only="true"
								:autofocus="false"
								:title="form.departureTitle[0]"
								:placeholder="$t('from_placeholder')"
								:input-class="'lg:rounded-r-none'"
								:error="departureError"
								:error-text="$t('from_required')"
								:input-class-error="'rounded-t-md lg:rounded-tr-none'"
								@update:title="form.departureTitle[0] = $event"
								@update:error="departureError = $event"
								:destinations="[
									{name: $t('TAS'), code: 'TAS'},
									{name: $t('SKD'), code: 'SKD'},
									{name: $t('MOW'), code: 'MOW'},
									{name: $t('IST'), code: 'IST'},
									{name: $t('FEG'), code: 'FEG'},
								]"
							/>
						</div>

						<div class="relative lg:flex-grow input-fields-distance lg:mt-0 xl:border-my-gray-400 xl:border-r">
							<AirportHints
								v-model="form.arrival[0]"
								:show-airports-only="true"
								:title="form.arrivalTitle[0]"
								:placeholder="$t('where_placeholder')"
								:input-class="'lg:rounded-l-none xl:rounded-r-none'"
								:ico-rotate="true"
								:rtl="true"
								:error="arrivalError || identicalCities[0] === true"
								:error-text="identicalCities[0] === true ? $t('where_equal') : $t('where_required')"
								:input-class-error="'rounded-t-md lg:rounded-tl-none xl:rounded-tr-none'"
								@update:title="form.arrivalTitle[0] = $event"
								@update:error="arrivalError = identicalCities[0] = $event"
								:destinations="[
									{name: $t('TAS'), code: 'TAS'},
									{name: $t('MOW'), code: 'MOW'},
									{name: $t('SKD'), code: 'SKD'},
									{name: $t('IST'), code: 'IST'},
									{name: $t('LED'), code: 'LED'},
								]"
							/>
							<div
								class="absolute z-[1] top-1/2 -mt-16 lg:mt-0 lg:left-0 right-4 lg:right-auto lg:-translate-x-4 translate-y-1 sm:-translate-y-0.5 lg:-translate-y-[50%] w-8 h-8 flex items-center justify-center border border-gray-300 bg-white rounded-full shadow-md cursor-pointer"
								@click="exchange({
									index: 0,
									departure: {
										model: form.departure[0],
										title: form.departureTitle[0]
									},
									arrival: {
										model: form.arrival[0],
										title: form.arrivalTitle[0]
									}
								})"
							>
								<img src="/design/exchange.svg" class="w-4 rotate-90 lg:rotate-0" width="16" height="16" alt="" />
							</div>
						</div>
					</div>

					<div class="lg:flex xl:flex-grow">
						<div class="input-fields-distance xl:mt-0 flex lg:flex-grow lg:w-1/2">
							<div class="flex-grow border-my-gray-400 border-r">
								<AviaCalendar
									v-model="form.departureDate[0]"
									:input-class="'rounded-r-none xl:rounded-l-none'"
									:first-of-two="true"
									:placeholder="$t('there')"
									:error="departureDateError"
									:error-text="$t('date_required')"
									:input-class-error="'rounded-t-md rounded-tr-none xl:rounded-tl-none'"
									@update:error="departureDateError = $event"
								/>
							</div>
							<div class="flex-grow lg:border-my-gray-400 lg:border-r input-fields-distance mt-0">
								<AviaCalendar
									v-model="form.dateBack"
									:input-class="'rounded-l-none lg:rounded-r-none'"
									:previous-date="form.departureDate[0]"
									:placeholder="$t('back')"
									:addCancel="!!form.dateBack"
								/>
							</div>
						</div>

						<div class="input-fields-distance xl:mt-0 sm:flex lg:flex-grow lg:w-1/2">
							<div class="sm:flex-grow sm:w-1/2 lg:w-1/4">
								<Multiselect
									v-model="form.passengers"
									:options="[
										{ value: 1, label: '1' },
										{ value: 2, label: '2' },
										{ value: 3, label: '3' },
										{ value: 4, label: '4' },
										{ value: 5, label: '5' },
										{ value: 6, label: '6' },
										{ value: 7, label: '7' },
										{ value: 8, label: '8' },
										{ value: 9, label: '9' },
									]"
									:canClear="false"
									class="h-[48px] sm:h-[60px] !border-none !rounded-md sm:!rounded-r-none lg:!rounded-none"
									:classes="{
										containerActive: 'is-active z-[1] !shadow-highlight',
										caret: 'multiselect-caret !mr-2',
										dropdown: 'multiselect-dropdown !border-gray-200',
										options: 'multiselect-options !max-h-96',
										option: 'multiselect-option !block !px-3 !py-1 hover:!bg-my-blue hover:!text-white',
										optionPointed: 'is-pointed !bg-my-blue !text-white',
										optionSelected: 'is-selected !bg-my-blue/20 !text-inherit',
										optionSelectedPointed: 'is-selected is-pointed !bg-my-blue !text-white'
									}"
								>
									<template #singlelabel="{ value }">
										<div class="multiselect-single-label !pl-4 !pr-10">
											<span class="multiselect-single-label-text text-my-black font-bold text-base sm:text-lg">
												{{ $tc('passenger_count', value.value) }}
											</span>
										</div>
									</template>
									<template #caret>
										<img
											src="/design/user-field.svg"
											class="absolute right-4 w-input-ico"
											alt=""
										/>
									</template>
								</Multiselect>
							</div>

							<div class="sm:hidden mt-3 text-left opacity-75">
								<span
									class="inline-block w-4 h-4 border-2 border-white rounded-lg align-middle cursor-pointer"
									:class="{'bg-white': moses}"
									@click="mosesToggle"
								/>
								<span
									class="inline-block text-white ml-3 align-middle cursor-pointer"
									@click="mosesToggle"
								>
									<template v-if="moses">{{ $t('easy_route') }}</template>
									<template v-else>{{ $t('multi_route') }}</template>
								</span>
							</div>

							<div class="mt-4 sm:mt-0 sm:flex-grow text-center sm:w-1/2 lg:w-1/4">
								<button
									class="relative focus:z-[1] w-full py-3 sm:py-4 rounded-md sm:rounded-none sm:rounded-r-md bg-gradient-to-b focus:bg-gradient-to-t focus:shadow-highlight hover:bg-gradient-to-t from-my-blue-from to-my-blue-to text-white text-base sm:text-lg font-bold cursor-pointer"
									@click="submitForm"
								>
									{{ $t('submit') }}
								</button>
							</div>
						</div>
					</div>
				</div>

		</div>

		<!--Moses-->
		<div class="mt-6" v-else>

				<div
					v-for="id in mosesBoxes"
					class="relative moses-box lg:flex"
					:class="{'input-fields-distance': id > 1}"
					:key="id"
					:data-id="id"
				>
					<div class="lg:flex-grow lg:w-1/3 lg:border-my-gray-400 lg:border-r">
						<AirportHints
							v-model="form.departure[id - 1]"
							:show-airports-only="true"
							:title="form.departureTitle[id - 1]"
							:placeholder="$t('from_placeholder')"
							:input-class="'lg:rounded-r-none'"
							:error="id === 1 && departureError"
							:error-text="id === 1 ? $t('from_required') : null"
							:input-class-error="'rounded-t-md lg:rounded-tr-none'"
							@update:title="form.departureTitle[id - 1] = $event"
							@update:error="id === 1 ? departureError = $event : null"
							:destinations="[
								{name: $t('TAS'), code: 'TAS'},
								{name: $t('MOW'), code: 'MOW'},
								{name: $t('SKD'), code: 'SKD'},
								{name: $t('UGC'), code: 'UGC'},
								{name: $t('FEG'), code: 'FEG'},
							]"
						/>
					</div>

					<div class="input-fields-distance relative lg:mt-0 lg:flex-grow lg:w-1/3 lg:border-my-gray-400 lg:border-r" :data-error="identicalCities[id]">
						<AirportHints
							v-model="form.arrival[id - 1]"
							:show-airports-only="true"
							:title="form.arrivalTitle[id - 1]"
							:placeholder="$t('where_placeholder')"
							:input-class="'lg:rounded-none'"
							:ico-rotate="true"
							:error="(id === 1 && arrivalError) || identicalCities[id] === true"
							:error-text="identicalCities[id] === true ? $t('where_equal') : id === 1 ? $t('where_required') : null"
							:input-class-error="'rounded-t-md lg:rounded-t-none'"
							@update:title="form.arrivalTitle[id - 1] = $event"
							@update:error="id === 1 ? arrivalError = identicalCities[id] = $event : identicalCities[id] = $event"
							:destinations="[
								{name: $t('MOW'), code: 'MOW'},
								{name: $t('IST'), code: 'IST'},
								{name: $t('DXB'), code: 'DXB'},
								{name: $t('TAS'), code: 'TAS'},
								{name: $t('LED'), code: 'LED'},
							]"
						/>
						<div
							class="absolute z-[1] lg:left-0 right-4 lg:right-auto top-1/2 -mt-16 lg:mt-0 lg:-translate-x-4 translate-y-1 sm:-translate-y-0.5 lg:-translate-y-[50%] flex items-center justify-center w-8 h-8 border border-gray-300 bg-white rounded-full shadow-md cursor-pointer"
							@click="exchange({
									index: id - 1,
									departure: {
										model: form.departure[id - 1],
										title: form.departureTitle[id - 1]
									},
									arrival: {
										model: form.arrival[id - 1],
										title: form.arrivalTitle[id - 1]
									}
								})"
						>
							<img src="/design/exchange.svg" class="w-4 rotate-90 lg:rotate-0" width="16" height="16" alt="" />
						</div>
					</div>

					<div class="input-fields-distance lg:mt-0 lg:flex-grow lg:w-1/3">
						<AviaCalendar
							v-model="form.departureDate[id - 1]"
							:input-class="'lg:rounded-l-none'"
							:first-of-two="true"
							:placeholder="$t('departure_date')"
							:previous-date="form.departureDate[id - 2]"
							:error="id === 1 && departureDateError"
							:error-text="$t('date_required')"
							:input-class-error="'rounded-t-md lg:rounded-tl-none'"
							@update:error="id === 1 ? departureDateError = $event : null"
						/>
					</div>

					<img
						v-if="id > 1"
						src="/design/cancel.svg"
						class="absolute z-[1] w-6 -top-1.5 -right-1 cursor-pointer"
						@click="removeMosesBox"
						:data-id="id - 1"
						alt
					/>
				</div>

				<div class="lg:flex input-fields-distance">
					<div class="lg:flex-grow lg:w-1/3">
						<Multiselect
							v-model="form.passengers"
							:options="[
								{ value: 1, label: '1' },
								{ value: 2, label: '2' },
								{ value: 3, label: '3' },
								{ value: 4, label: '4' },
								{ value: 5, label: '5' },
								{ value: 6, label: '6' },
								{ value: 7, label: '7' },
								{ value: 8, label: '8' },
								{ value: 9, label: '9' },
							]"
							:canClear="false"
							class="h-[48px] sm:h-[60px] !border-none !rounded-md lg:!rounded-r-none"
							:classes="{
								containerActive: 'is-active z-[1] !shadow-highlight',
								caret: 'multiselect-caret !mr-2',
								dropdown: 'multiselect-dropdown !border-gray-200',
								options: 'multiselect-options !max-h-96',
								option: 'multiselect-option !block !px-3 !py-1 hover:!bg-my-blue hover:!text-white',
								optionPointed: 'is-pointed !bg-my-blue !text-white',
								optionSelected: 'is-selected !bg-my-blue/20 !text-inherit',
								optionSelectedPointed: 'is-selected is-pointed !bg-my-blue !text-white'
							}"
						>
							<template #singlelabel="{ value }">
								<div class="multiselect-single-label !pl-4 !pr-10">
									<span class="multiselect-single-label-text text-my-black font-bold text-base sm:text-lg">
										{{ $tc('passenger_count', value.value) }}
									</span>
								</div>
							</template>
							<template #caret>
								<img
									src="/design/user-field.svg"
									class="absolute right-4 w-input-ico"
									alt=""
								/>
							</template>
						</Multiselect>
					</div>

					<div class="sm:hidden mt-3 text-left opacity-75">
						<span
							class="inline-block w-4 h-4 border-2 border-white rounded-lg align-middle cursor-pointer"
							:class="{'bg-white': moses}"
							@click="mosesToggle"
						/>
						<span
							class="inline-block text-white ml-3 align-middle cursor-pointer"
							@click="mosesToggle"
						>
							<template v-if="moses">{{ $t('easy_route') }}</template>
							<template v-else>{{ $t('multi_route') }}</template>
						</span>
					</div>

					<div class="mt-4 sm:mt-6 lg:mt-0 lg:flex-grow flex lg:w-2/3">
						<button
							type="button"
							class="relative focus:z-[1] py-3 sm:py-4 bg-gradient-to-b focus:bg-gradient-to-t focus:shadow-highlight hover:bg-gradient-to-t from-my-gray-from to-my-gray-to text-white text-base sm:text-lg font-bold text-center flex-grow w-1/2 rounded-l-md lg:rounded-l-none cursor-pointer"
							@click="addMosesBox"
						>
							{{ $t('add_flight') }}
						</button>
						<button
							class="relative focus:z-[1] py-3 sm:py-4 bg-gradient-to-b focus:bg-gradient-to-t focus:shadow-highlight hover:bg-gradient-to-t from-my-blue-from to-my-blue-to text-white text-base sm:text-lg font-bold text-center flex-grow w-1/2 rounded-r-md cursor-pointer"
							@click="submitForm"
						>
							{{ $t('submit') }}
						</button>
					</div>
				</div>
		</div>
	</div>
</template>

<script>
import AirportHints from './AirportHints'
import AviaCalendar from './AviaCalendar'
import Passengers from './Passengers'
import Multiselect from './Multiselect'

export default {
	name: 'AviaSearchForm',

	components: {
		AirportHints,
		AviaCalendar,
		Passengers,
		Multiselect,
	},

	props: {
		requestData: Object,
		selectedDeparture: Object,
		selectedArrival: Object,
	},

	data() {
		return {
			form: {
				departure: this.selectedDeparture ? [this.selectedDeparture.code] : [],
				departureTitle: this.selectedDeparture ? [this.selectedDeparture.name] : [],
				arrival: this.selectedArrival ? [this.selectedArrival.code] : [],
				arrivalTitle: this.selectedArrival ? [this.selectedArrival.name] : [],
				departureDate: [],
				dateBack: null,
				passengers: 1,
				// adults: 1,
				// children: 0,
				// infants: 0,
				// class: 'A'
			},

			departureError: false,
			arrivalError: false,
			identicalCities: [],
			departureDateError: false,

			hintBox: false,
			airports: [],
			moses: false,
			mosesBoxes: 2,
			//calendarDefaultDate: null,
		}
	},
	mounted() {
		this.setFormData()
	},
	methods: {
		/**
		 *
		 */
		mosesToggle() {
			this.moses = !this.moses;
		},

		/**
		 *
		 */
		addMosesBox() {
			++this.mosesBoxes;
		},

		/**
		 *
		 */
		removeMosesBox(event) {
			const id = event.target.dataset.id;

			this.form.departure.splice(id, 1);
			this.form.departureTitle.splice(id, 1);
			this.form.arrival.splice(id, 1);
			this.form.arrivalTitle.splice(id, 1);
			this.form.departureDate.splice(id, 1);

			if (this.mosesBoxes < 3) {
				this.moses = false;
			} else {
				--this.mosesBoxes;
			}
		},

		exchange({index, departure, arrival}) {
			if (departure.model || arrival.model) {
				this.form.departure.splice(index, 1, arrival.model)
				this.form.departureTitle.splice(index, 1, arrival.title)
				this.form.arrival.splice(index, 1, departure.model)
				this.form.arrivalTitle.splice(index, 1, departure.title)
			}
		},

		/**
		 *
		 */
		validateForm() {
			if (!this.form.departure[0]) {
				this.departureError = true
			}

			if (!this.form.arrival[0]) {
				this.arrivalError = true
			}

			if (!this.form.departureDate[0]) {
				this.departureDateError = true
			}

			return !(this.departureError || this.arrivalError || this.departureDateError)
		},

		submitForm() {
			if (!this.validateForm()) {
				return
			}

			let query = [];
			const directions = [];

			this.identicalCities = [];

			if (this.moses) {
				for (let index = 0; index < this.mosesBoxes; ++index) {
					if (this.form.departure[index] === this.form.arrival[index]) {
						this.identicalCities[index + 1] = true;
						return;
					}

					if (!this.form.departure[index] ||
						!this.form.arrival[index] ||
						!this.form.departureDate[index]
					) {
						continue;
					}

					directions.push(`${this.form.departure[index]}-${this.form.arrival[index]}-${this.parseDate(this.form.departureDate[index])}`);
				}
			} else {
				if (this.form.departure[0] === this.form.arrival[0]) {
					this.identicalCities[0] = true;
					return;
				}

				directions.push(`${this.form.departure[0]}-${this.form.arrival[0]}-${this.parseDate(this.form.departureDate[0])}`);

				if (!!this.form.dateBack) {
					directions.push(`${this.form.arrival[0]}-${this.form.departure[0]}-${this.parseDate(this.form.dateBack)}`);
				}
			}

			query = query.concat([
				// this.form.class,
				// this.form.adults,
				// this.form.children,
				// this.form.infants
			]).join('_');

			if (query === this.$route.params.aviaQuery && this.$route.name === 'insurance') {
				this.$emit('refreshPage')
			} else {
				this.$router.push({
					name: 'insurance',
					query: {
						directions: directions,
						passengers: this.form.passengers,
					}
				})
			}
		},

		/**
		 *
		 */
		parseDate(date) {
			return `${date.getFullYear()}${this.addZero(date.getMonth() + 1)}${this.addZero(date.getDate())}`
		},

		setFormData() {
			const serverDate = new Date(this.$store.getters['serverDate']).setHours(0, 0, 0, 0)

			if (this.requestData) {
				this.form = {
					departure: [],
					departureTitle: [],
					arrival: [],
					arrivalTitle: [],
					departureDate: [],
					dateBack: null,
					adults: 1,
					children: 0,
					infants: 0,
					class: 'A'
				}

				this.form.adults = this.requestData.adults;
				this.form.children = this.requestData.children;
				this.form.infants = this.requestData.infants;
				this.form.class = this.requestData.serviceClass;

				const directions = this.requestData.directions;

				for (let direction of directions) {
					const departureDate = new Date(Date.parse(direction.dateISO))

					this.form.departure.push(direction.departureAirportCode);
					this.form.departureTitle.push(direction.departureAirport);
					this.form.arrival.push(direction.arrivalAirportCode);
					this.form.arrivalTitle.push(direction.arrivalAirport);

					if (departureDate >= serverDate) {
						this.form.departureDate.push(departureDate);
					} else {
						this.form.departureDate.push(null);
					}
				}

				if (directions.length === 2 &&
					directions[0].departureAirportCode === directions[1].arrivalAirportCode &&
					directions[0].arrivalAirportCode === directions[1].departureAirportCode
				) {
					const dateBack = new Date(Date.parse(directions[1].dateISO))

					if (dateBack >= serverDate) {
						this.form.dateBack = dateBack;
					}
				} else if (directions.length >= 2) {
					this.moses = true;
					this.mosesBoxes = directions.length;
				}
			}
		},
	},
	computed: {
		href() {
			let query = []
			let error = false

			if (!this.form.departure[0] ||
				!this.form.arrival[0] ||
				!this.form.departureDate[0] ||
				this.form.departure[0] === this.form.arrival[0]
			) {
				error = true
			}

			if (error) {
				return '#'
			}

			query.push(`${this.form.departure[0]}-${this.form.arrival[0]}-${this.parseDate(this.form.departureDate[0])}`)

			if (!!this.form.dateBack) {
				query.push(`${this.form.arrival[0]}-${this.form.departure[0]}-${this.parseDate(this.form.dateBack)}`)
			}

			query = query.concat([
				this.form.class,
				this.form.adults,
				this.form.children,
				this.form.infants
			]).join('_')

			return `/${this.$i18n.locale}/avia/offers/${query}`
		}
	}
}
</script>
