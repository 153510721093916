<i18n lang="json5">{
	ru: {
		title: 'Дешёвые авиабилеты на Bookhara от более 800 авиакоманий. Покупайте билеты на самолёт онлайн',
		meta_description: 'Быстрый и удобный поиск билетов на самолет. Найдем нужные билеты из Ташкента и других аэропортов Узбекистана в любую точку мира, чтобы Вы могли сэкономить и купить дешевый авиабилет.',
		avia_tagline_top: 'Дешевые билеты на самолет',
		avia_tagline_bottom: 'дешевле, только если идти пешком',
		insurance_tagline_top: 'Рекомендуем застраховать поездку',
		insurance_tagline_bottom: 'обеспечив защиту в случае болезни, утраты багажа, отмены рейса',
		transfer_tagline_top: 'Позаботьтесь о трансфере заранее',
		transfer_tagline_bottom: 'заказав его онлайн, Вы сэкономите деньги и сохраните нервы',
		train_tagline_top: 'Самый надёжный самолет – это поезд',
		train_tagline_bottom: 'билет на который куплен онлайн без очередей и шапок',
		tab_avia: 'Авиабилеты',
		tab_insurance: 'Страхование',
		tab_train: 'Поезд',
		tab_bus: 'Автобус',
		tab_hotel: 'Отель',
		tab_transfer: 'Трансфер',
		tab_business_lounge: 'Бизнес-зал',
		description_title: 'Покупайте самые дешевые авиабилеты на Bookhara.uz',
		description_text: 'Для современных путешественников, которых не устраивает рабочий график, цены и ограниченность предложений классических авиакасс, мы предлагаем в круглосуточном режиме приобретать авиа билеты онлайн от более чем 800 авиакомпаний, по самым низким ценам.\nУдобный и быстрый поиск билетов, умные фильтры, минимум полей, позволяют найти, забронировать и купить авиабилет буквально за 5 минут.\nВ отличии от зарубежных аналогов, мы предоставляем оперативную поддержку на русском и узбекском языках, а также возможность оплаты за билет в национальной валюте любым удобным способом, включая рассрочку.\nТолько у нас во время покупки авиа билета, можно одним кликом оформить целый спектр туристических страховок.\nМы предоставляем онлайн билеты на понятных даже ребенку бланках.\nЭкономьте время, нервы и деньги, наслаждаясь простым и удобным сервисом с безопасной оплатой и заботливой поддержкой.',
	},
	uz: {
		title: 'Bookhara.uz saytida 800 dan ortiq aviakompaniyalardan arzon aviachiptalar. Samolyot biletlarni onlayn xarid qiling',
		meta_description: 'Samolyot chiptalarini tez va qulay qidirish. Pulni tejash va arzon aviabilet sotib olishingiz uchun biz Toshkent va Oʼzbekistonning boshqa aeroportlaridan dunyoning istalgan nuqtasiga kerakli chiptalarni topamiz.',
		avia_tagline_top: 'Arzon samolyot chiptalari',
		avia_tagline_bottom: 'Bundan arzoni faqat piyoda',
		insurance_tagline_top: 'Safaringizni sugʼurtalang',
		insurance_tagline_bottom: 'va kasallik yetganda, yuk yoʼqolganda yoki reys bekor qilinganda ham xotirjam boʼling',
		transfer_tagline_top: 'Позаботьтесь о трансфере заранее',
		transfer_tagline_bottom: 'заказав его онлайн, Вы сэкономите деньги и сохраните нервы',
		train_tagline_top: 'Самый надёжный самолет – это поезд',
		train_tagline_bottom: 'билет на который куплен онлайн без очередей и шапок',
		tab_avia: 'Aviachiptalar',
		tab_insurance: 'Sugʼurta',
		tab_train: 'Poyezd',
		tab_bus: 'Avtobus',
		tab_hotel: 'Mehmonxona',
		tab_transfer: 'Transfer',
		tab_business_lounge: 'Biznes zali',
		description_title: 'Eng arzon aviachiptalarni Bookhara.uz saytidan xarid qiling.',
		description_text: 'Klassik aviakassalarning ish jadvali, narxlari va cheklangan takliflaridan qoniqmagan zamonaviy sayohatchilar uchun, biz 800 dan ortiq aviakompaniyalardan eng arzon narxlarda aviachiptalarni 24/7 onlayn xarid qilishni taklif etamiz.\nChiptalarni qulay va tezkor qidirish, aqlli filtrlar va minimal maydonlar sizga 5 daqiqada aviabiletni topish, bron qilish va sotib olish imkonini beradi.\nXorijiy analoglardan farqli, biz rus va oʼzbek tillarida operativ yordam koʼrsatamiz, shuningdek, bilet milliy valyutada istalgan qulay usulda toʼlash imkoniyatini taqdim etamiz.\nFaqat bizda, aviachipta sotib olayotganda, siz bir marta bosish orqali sayohat sugʼurtasining butun spektrini rasmiylashtirishingiz mumkin.\nBiz onlayn aviabiletni hatto yosh bola ham tushuna oladigan shakllarda taqdim etamiz.\nXavfsiz toʼlov va gʼamxoʼrlik yordami bilan oddiy va qulay xizmatdan bahramand boʼlish orqali vaqt, stress va pulni tejang.',
	},
}</i18n>

<template>
	<div class="flex flex-col xl:container xl:mx-auto lg:h-full">
		<div class="flex flex-col justify-center grow pt-6 sm:pt-12 lg:pt-28 lg:pb-20">
			<keep-alive>
				<TabInsurance v-if="activeTab === 'insurance'" inline-template>
					<div>
						<h2 class="text-xl sm:text-2xl lg:text-4xl text-white font-bold">
							{{ $parent.$t('insurance_tagline_top') }}
						</h2>
						<h3 class="text-base sm:text-lg lg:text-xl text-white mb-8 sm:mb-12">
							{{ $parent.$t('insurance_tagline_bottom') }}
						</h3>
						<InsuranceSearchForm />
					</div>
				</TabInsurance>
				<TabTransfer v-if="activeTab === 'transfer'" inline-template>
					<div>
						<h2 class="text-xl sm:text-2xl lg:text-4xl text-white font-bold">
							{{ $parent.$t('transfer_tagline_top') }}
						</h2>
						<h3 class="text-base sm:text-lg lg:text-xl text-white mb-8 sm:mb-12">
							{{ $parent.$t('transfer_tagline_bottom') }}
						</h3>
						<TransferSearchForm />
					</div>
				</TabTransfer>
				<TabTrain v-if="activeTab === 'train'" inline-template>
					<div>
						<h2 class="text-xl sm:text-2xl lg:text-4xl text-white font-bold">
							{{ $parent.$t('train_tagline_top') }}
						</h2>
						<h3 class="text-base sm:text-lg lg:text-xl text-white mb-8 sm:mb-12">
							{{ $parent.$t('train_tagline_bottom') }}
						</h3>
						<TrainSearchForm />
					</div>
				</TabTrain>
				<TabAvia v-else inline-template>
					<div>
						<h1 class="text-xl sm:text-2xl lg:text-4xl text-white font-bold">
							{{ $parent.$t('avia_tagline_top') }}
						</h1>
						<h2 class="text-base sm:text-lg lg:text-xl text-white mb-8 sm:mb-12">
							{{ $parent.$t('avia_tagline_bottom') }}
						</h2>
						<AviaSearchForm />
					</div>
				</TabAvia>
			</keep-alive>
		</div>

		<div
			v-if="testServer"
			class="mt-20 lg:mt-0 mb-10"
		>
			<div>
				<hr class="border-my-gray border-t"/>

				<div class="flex flex-nowrap overflow-x-auto">
					<div
						class="home-form-switch"
						:class="{'home-form-switch-active': activeTab === 'avia'}"
						@click="activeTab = 'avia'"
					>
						{{ $t('tab_avia') }}
					</div>
					<div class="home-form-switch" v-if="false">
						{{ $t('tab_bus') }}
					</div>
					<div
						class="home-form-switch"
						:class="{'home-form-switch-active': activeTab === 'insurance'}"
						@click="activeTab = 'insurance'"
					>
						{{ $t('tab_insurance') }}
					</div>
					<div class="home-form-switch" v-if="false">
						{{ $t('tab_hotel') }}
					</div>
					<div
						v-if="false && testServer"
						class="home-form-switch"
						:class="{'home-form-switch-active': activeTab === 'transfer'}"
						@click="activeTab = 'transfer'"
					>
						{{ $t('tab_transfer') }}
					</div>
					<div
						v-if="false && testServer"
						class="home-form-switch"
						:class="{'home-form-switch-active': activeTab === 'train'}"
						@click="activeTab = 'train'"
					>
						{{ $t('tab_train') }}
					</div>
					<div class="home-form-switch" v-if="false">
						{{ $t('tab_business_lounge') }}
					</div>
				</div>
			</div>
		</div>

		<div class="mt-20 lg:mt-0 mb-10 text-white">
			<h3 class="mb-1 text-lg font-bold">
				{{ $t('description_title') }}
			</h3>
			<div class="whitespace-pre-line">
				{{ $t('description_text') }}
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import AviaSearchForm from '~/components/AviaSearchForm'
import InsuranceSearchForm from '~/components/InsuranceSearchForm'
import TransferSearchForm from '~/components/TransferSearchForm'
import TrainSearchForm from '~/components/TrainSearchForm'
import {testServer} from '../../nuxt.config.js'

Vue.component('TabAvia', {
	components: {
		AviaSearchForm,
	},
})

Vue.component('TabInsurance', {
	components: {
		InsuranceSearchForm,
	},
})

Vue.component('TabTransfer', {
	components: {
		TransferSearchForm,
	},
})

Vue.component('TabTrain', {
	components: {
		TrainSearchForm,
	},
})

export default {
	name: 'Home',
	head() {
		return {
			title: this.$t('title'),
			meta: [{
				hid: 'description',
				name: 'description',
				content: this.$t('meta_description'),
			}],
		}
	},
	data() {
		return {
			activeTab: this.$route.query.tab || 'avia',
			testServer: testServer,
		}
	},
	watch: {
		activeTab(newTab, oldTab) {
			this.$parent.$refs.home.classList.remove('bg-' + oldTab)
			this.$parent.$refs.home.classList.add('bg-' + newTab)
			window.scrollTo(0, 0)
			this.$router.replace({name: 'home', query: {tab: newTab}})
		},
		async $route(to) {
			this.activeTab = to.query.tab || 'avia'
		},
	},
}
</script>
